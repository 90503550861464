import React, {useEffect, useState} from 'react';
import JoinForm from "../Volunteer/JoinForm";
import ApiCrud from "../../../API/services";
import {LoadingSpinner} from "../../../Components/Atoms/LoadingSpin";

const LaySpiritanContainer = () => {
    const [data, setData] = useState<any>()

    useEffect(() => {
        const AbortRequest = new AbortController()
        const {getAll} = ApiCrud({endpoint: `get_involved/lay_spiritan_page/`, signal: AbortRequest.signal})
        getAll()
            .then((res) => {
                return (
                    setData(res.data.items[0])
                )
            })
            .catch((err) => {
                if (err.code === 'ERR_CANCELED') {
                    return
                }
            })

        return () => {
            setData(undefined)
            AbortRequest.abort()
        }
    }, []);
    return (
        <>
            {data ?
                <div className={'page page-padding lay-spiritan-container'}>
                    <h1>Lay Spiritans</h1>
                    <div className="lay-spiritan-intro">
                        <div className="intro-image">
                            <img src={data.header_image.url} alt="Lay Spiritans"/>
                            {/*<img src={MainURL + data.header_image.url} alt="Lay Spiritans"/>*/}
                        </div>
                        <div className="intro-content">
                            <p>
                                {data.header_text}
                            </p>
                        </div>
                    </div>
                    <div className="lay-spiritan-body">
                        <div className="lay-spiritan-info">
                            <h3>{data.description_title}</h3>
                            <p>
                                {data.description}
                            </p>
                        </div>
                        <div className="lay-spiritan-form">
                            <JoinForm
                                endPoint={'people/lay_spiritan/'}
                                submitButtonName={'join_us'}
                            />
                        </div>
                    </div>

                </div>
                :
                <div className={"page loading-spinner"}>
                    <LoadingSpinner/>
                </div>
            }
        </>
    );
};

export default LaySpiritanContainer;