import React, {useEffect, useState} from 'react';
import ApiCrud from "../../../API/services";
import JobCard from "./jobCard";
import {LoadingSpinner} from "../../../Components/Atoms/LoadingSpin";

const EmploymentContainer = () => {
    const [data, setData] = useState<any>()

    useEffect(() => {
        const AbortRequest = new AbortController()
        const {getAll} = ApiCrud({endpoint: `jobs/`, signal: AbortRequest.signal})
        getAll()
            .then((res) => {
                return (
                    setData(res.data.items)
                )
            })
            .catch((err) => {
                if (err.code === 'ERR_CANCELED') {
                    return
                }
            })

        return () => {
            setData(undefined)
            AbortRequest.abort()
        }
    }, []);
    return (
        <div className={'page page-padding employment-container'}>
            {data ?
                <div className="employment-body">
                    {
                        data.map((job: any) => {
                            return (
                                <JobCard job={job}/>
                            )
                        })
                    }
                </div>
                :
                <div className={"page loading-spinner"}>
                    <LoadingSpinner/>
                </div>
            }
        </div>
    );
};

export default EmploymentContainer;