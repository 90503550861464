import React from 'react';
import RedLogo from '../../../Assets/Images/spinner-logo-red.svg'
import WhiteLogo from '../../../Assets/Images/spinner-logo-white.svg'
import './styles.scss'

const LoadingSpinnerContainer = () => {
    return (
        <div className={'loading-spinner-container'}>
            <object data={WhiteLogo} className={'spinner white-logo'}/>
            <object data={RedLogo} className={'spinner red-logo'}/>
        </div>
    );
};

export default LoadingSpinnerContainer;