import React from 'react';
import {IParishComponents} from "./interfaces";

const ParishInfoComponent = ({info}: IParishComponents) => {
    return (
        <span className="parish-card-content parish-card-description">
            <h3>About Parish</h3>
            <span>
                <p>
                    {info}
                </p>
            </span>
        </span>
    );
};

export default ParishInfoComponent;