import React, {useEffect} from 'react';
import {navbarBackgroundChanger, navbarButtonColorChanger} from "../../Helpers/navbarColorHelper";
import BGImage from '../../Assets/Images/Images/PHOTO-2023-05-19-12-30-54.jpg'
import {IHomePage} from "./interfaces";

const TestimonySection = (data: IHomePage) => {

    useEffect(() => {
        navbarButtonColorChanger('#d0bdbd');
        navbarBackgroundChanger('transparent')
        return () => {
            navbarButtonColorChanger('#091b27')
            navbarBackgroundChanger('var(--body-background)')
        }

    }, []);

    const testimonies = data.testimonial
    return (
        <section className="page-padding home-section home-testimony">
            {
                testimonies &&
                <div className="testimony-container">
                    <div className="testimony-first">
                        {testimonies.map((testimony, index) => {
                            return (
                                <div className="testimony-card" key={`testimony-${index}`}>
                                    <div className="testimony-image">
                                        <img src={testimony.author_image.url} alt={testimony.author}/>
                                        {/*<img src={MainURL + testimony.author_image.url} alt={testimony.author}/>*/}
                                    </div>
                                    <div className="testimony-content">
                                        <p className={'quote'}>
                                            {testimony.testimony}
                                        </p>
                                    </div>
                                    <div className="testimony-author">
                                        <div className="testimony-author-name">
                                            <h3>{testimony.author}</h3>
                                        </div>
                                        <div className="testimony-author-position">
                                            <big>{testimony.author_title}</big>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="testimony-second" style={{backgroundImage: `url(${BGImage})`}}>
                        <span className="phrase"><h3>Give</h3></span>
                        <span className="phrase"><h3>Love</h3></span>
                        <span className="phrase"><h3>Protect</h3></span>
                    </div>
                </div>
            }
        </section>
    );
};

export default TestimonySection;