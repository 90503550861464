import React, {useEffect, useState} from "react";
import ApiCrud from "../../../API/services";
import {useNavigate} from "react-router";


const DonationContainer = () => {
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            const {create} = ApiCrud({endpoint: 'donation/success/', data: {session_id: query.get("session_id")}})
            create().then()
            setSuccess(true)
            navigate('success')
        }

        if (query.get("canceled")) {
            setSuccess(false)
            navigate(query.get("cancel_url") ?? '/')
        }
    }, []);

    return (
        <>
        </>
    );
};

export default DonationContainer;