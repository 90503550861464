import React from 'react';
import Logo from "../../Assets/Images/logo.svg";
import {Link} from "react-router-dom";
import HeroIMG from "../../Assets/Images/heroBG.jpeg";
import {IHomePage} from "./interfaces";


const HeroSection = (data: IHomePage) => {
    return (
        <section className="home-section home-hero-section">
            <div className="hero hero-intro">
                <div className="hero-section-brand">
                    <img
                        src={data.header_logo.url}
                        // src={MainURL + data.header_logo.url}
                        width="40"
                        height="60"
                        className="hero-section-logo"
                        alt="React Bootstrap logo"
                    />
                    <h3>{data.header_title}</h3>
                </div>
                <div className="hero-section-body">
                    <h1 className='hero-section-title'>
                        {data.hero_section_title}
                    </h1>
                    <h1 className='hero-section-title'>{data.hero_section_second_title}</h1>
                    <big className="hero-section-content">
                        {data.hero_section_body}

                        <small className="hero-extra">
                            <Link to={data.hero_section_link_url}
                                  className={'hero-extra-link'}>{data.hero_section_link_name}</Link>
                        </small>
                    </big>
                </div>
                <Link to={data.hero_section_button_link} className="hero-cta">
                    {data.hero_section_button_name}
                </Link>
            </div>
            <div className="hero hero-image" style={{backgroundImage: `url(${HeroIMG})`}}>
                <img
                    className={'hero-image-text'}
                    style={{maskImage: `url(${Logo})`, WebkitMaskImage: `url(${Logo})`}}
                    src={HeroIMG}
                    alt="Congregation of the Holy Spirit fathers australia"
                />
            </div>
        </section>
    );
};

export default HeroSection;