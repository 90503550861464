import {HiLocationMarker, HiMail, HiPhone} from "react-icons/hi";
import DefaultUser from '../../Assets/Images/default-user.png'

export function LeaderCard(leader: any) {
    return <div className="leaders-section-card">
        <div className="leader-image">
            {/*<img src={leader.image?.cropped ? MainURL + leader.image?.cropped : DefaultUser}*/}
            <img src={leader.image?.cropped ? leader.image?.cropped : DefaultUser}
                 alt={leader.name}/>
        </div>
        <div className="leader-info">
            <span className="leader-name">
                <h3>{leader.name}</h3>
            </span>
            <span className="leader-position">
                {leader.position ? <big>({leader.position})</big> : <big></big>}
            </span>
        </div>
        <div className="leader-contact">
            <div className="leader-contact-info">
                <span className="contact-icon">
                    <HiPhone/>
                </span>
                <span className="contact-info">
                    {leader.phone ?? '+6111111111'}
                </span>
            </div>
            <div className="leader-contact-info">
                <span className="contact-icon">
                    <HiMail/>
                </span>
                <span className="contact-info">
                    {leader.email ?? 'someone@example.com'}
                </span>
            </div>
            <div className="leader-contact-info">
                <span className="contact-icon">
                    <HiLocationMarker/>
                </span>
                <span className="contact-info">
                    {leader.address ?? 'Sydney, NSW AU'}
                </span>
            </div>
        </div>
    </div>;
}
