import React, {useEffect, useState} from 'react';
import ApiCrud from "../../../API/services";
import {useLocation} from "react-router";

const JobDetails = () => {
    const [job, setJob] = useState<any>()
    const location = useLocation()
    const id = location.pathname.split(`/`).at(-1)

    useEffect(() => {
        const AbortRequest = new AbortController()
        const {get} = ApiCrud({endpoint: `jobs/`, id: id, signal: AbortRequest.signal})
        get()
            .then((res) => {
                return (
                    setJob(res.data.items)
                )
            })
            .catch((err) => {
                if (err.code === 'ERR_CANCELED') {
                    return
                }
            })

        return () => {
            setJob(undefined)
            AbortRequest.abort()
        }
    }, []);
    return (
        <>
            {
                job &&
                <div className={'job-details-container page page-padding'}>
                    <div className="job-details-body">
                        <h3>{job.title}</h3>
                        <div className="job-card-sections">
                            <div className={'job-card-date job-card-section'}>
                                <big>Posted</big>
                                <p>{job.date_posted}</p>
                            </div>
                            <div className={'job-card-onsite job-card-section'}>
                                <big>Location</big>
                                <p>{job.on_site ? `On Site/ ${job.location}` : 'Remotely'}</p>
                            </div>
                            <div className={'job-card-details job-card-section'}>
                                <big>Job Brief</big>
                                <>
                                    {job.job_details.split('???').length > 1 ?
                                        <ul>
                                            {job.job_details.split('???').slice(0, -1).map((sentence: string) => {
                                                return (
                                                    <li>{sentence}</li>
                                                )
                                            })}
                                        </ul>
                                        :
                                        <p>{job.job_details}</p>
                                    }
                                </>

                            </div>
                            <div className={'job-card-responsibilities job-card-section'}>
                                <big>Your Responsibilities </big>
                                <>
                                    {job.responsibilities.split('???').length > 1 ?
                                        <ul>
                                            {job.responsibilities.split('???').slice(0, -1).map((sentence: string) => {
                                                return (
                                                    <li>{sentence}</li>
                                                )
                                            })
                                            }
                                        </ul>
                                        :
                                        <p>{job.responsibilities}</p>
                                    }
                                </>
                            </div>
                            <div className={'job-card-requirements job-card-section'}>
                                <big>Requirements</big>
                                <>
                                    {job.requirements.split('???').length > 1 ?
                                        <ul>
                                            {
                                                job.requirements.split('???').slice(0, -1).map((sentence: string) => {
                                                    return (
                                                        <li>{sentence}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                        :
                                        <p>{job.requirements}</p>
                                    }
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default JobDetails;
