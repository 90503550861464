import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {INavbar} from "./interfaces";
import './styles.scss'
import {SlMenu} from "react-icons/sl";
import {useGetWindowDimensions} from "../../../Helpers/screenHelpers";
import {useLocation} from "react-router";

const NavbarContainer = ({buttons, brandImageUrl}: INavbar) => {
    const location = useLocation()
    const [activeButton, setActiveButton] = useState(location.pathname.split('/')[1].split('_').join(' '))
    const [isToggled, setIsToggled] = useState(false)
    const [toggleNav, setToggleNav] = useState(false) // determines if navbar menu toggle needed

    const {width} = useGetWindowDimensions()
    const toggleHandler = () => {
        setIsToggled(!isToggled)
    }


    useEffect(() => {
        setActiveButton(location.pathname.split('/')[1].split('_').join(' ') === '' ? 'home' : location.pathname.split('/')[1].split('_').join(' '))
    }, [location])

    const selectHandler = (value: string) => {
        setIsToggled(!isToggled)
        setActiveButton(value.toLowerCase())
    }

    useEffect(() => {
        if (width < 1201) {
            setToggleNav(true)
            setIsToggled(false)
        } else {
            setToggleNav(false)
        }
    }, [width])

    // useEffect(()=>{
    //     console.log(activeButton)
    // }, [activeButton])

    return (
        <nav className="navbar-container">
            {brandImageUrl &&
                <span className="navbar-brand">
                    <img src={brandImageUrl} alt=""/>
                </span>
            }
            <ul className={`navbar-buttons ${toggleNav ? 'vertical' : 'horizontal'} ${toggleNav && !isToggled ? 'hide-menu' : 'show-menu'}`}>
                {buttons.map((button, index) => {
                    return (
                        <Link
                            to={button.link}
                            onClick={() => selectHandler(button.name)}
                        >
                            <li key={`navbar-link-${index}`}
                                className={`navbar-button ${activeButton.toLowerCase() === button.name.toLowerCase() && 'active'}`}>

                                {button.name}
                            </li>

                        </Link>
                    )
                })
                }
            </ul>

            {toggleNav &&
                <span className="navbar-toggle" onClick={toggleHandler}>
                        <SlMenu/>
                </span>
            }
        </nav>
    );
};

export default NavbarContainer;