import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router";
import {navbarBackgroundChanger} from "../../../Helpers/navbarColorHelper";
import {IBlog} from "../Blogs/interfaces";
import ApiCrud from "../../../API/services";

const BlogDetailsContainer = () => {
    const location = useLocation()
    const id = location.pathname.split(`/`).at(-1)
    const [blog, setBlog] = useState<IBlog>()


    useEffect(() => {
        navbarBackgroundChanger('transparent')
        const AbortRequest = new AbortController()
        const {get} = ApiCrud({endpoint: `blogs/`, id: id, signal: AbortRequest.signal})
        get()
            .then((res) => {
                return (
                    setBlog(res.data.items)
                )
            })
            .catch((err) => {
                if (err.code === 'ERR_CANCELED') {
                    return
                }
            })
        return () => {
            setBlog(undefined)
            AbortRequest.abort()
            navbarBackgroundChanger('var(--body-background)')
        }

    }, []);
    return (
        <>
            {
                blog &&
                <div className={'page page-padding blog-details-container'}>
                    <div className="blog-details-image">
                        <img src={blog.image.url} alt={blog.title}/>
                        {/*<img src={MainURL + blog.image.url} alt={blog.title}/>*/}
                        <h1>{blog.title}</h1>
                        <div className="blog-details-extras">
                            <small>{blog.author.name}</small>
                            <small>{blog.date}</small>
                        </div>
                    </div>
                    <div className="blog-details-content">
                        <div
                            className={`about-us-content`}
                            dangerouslySetInnerHTML={{__html: blog.content}}
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default BlogDetailsContainer;