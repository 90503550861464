import React from 'react';
import {IParishComponents} from "./interfaces";

const MassInfoComponent = ({massInfos}: IParishComponents) => {
    return (
        <span className="parish-card-content parish-card-mass">
            <h3>Mass Schedule</h3>
            <span>
                {massInfos?.map((mass_info: any, index: number) => {
                    return (
                        <div className={'parish-card-content-line'} key={`mass-info -${index}`}>
                            {mass_info.title &&
                                <big>
                                    {mass_info.title}:
                                </big>
                            }&nbsp;
                            <small>{mass_info.date}: {mass_info.time}</small>
                        </div>
                    )
                })}
            </span>
        </span>
    );
};

export default MassInfoComponent;