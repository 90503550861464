import React from "react";
import {Col} from "react-bootstrap";
import {Route, Routes, useLocation} from "react-router";
import {ContactUs} from "../ContactUs";
import {Gallery} from "../Gallery";
import {AboutUs} from "../AboutUs";
import {WhatWeDo} from "../WhatWeDo";
import {Parishes} from "../WhatWeDo/Parishes";
import {Education} from "../WhatWeDo/Education";
import {GetInvolved} from "../GetInvolved";
import {Donation, DonationSuccess} from "../GetInvolved/Donation";
import {Volunteer} from "../GetInvolved/Volunteer";
import {HomePage} from "../HomePage";
import {UnderConstruction} from "../UnderConstruction";
import {BlogsNews} from "../Blogs&Events";
import {BlogDetails} from "../Blogs&Events/blogDetails";
import {LaySpiritan} from "../GetInvolved/LaySpiritan";
import {UpcomingEvents} from "../UpcomingEvents";
import {Resources} from "../Resources";
import CommunityLife from "../Resources/communityLifeContainer";
import RuleOfLife from "../Resources/ruleOfLifeContainer";
import {Employment} from "../GetInvolved/Employment";
import JobDetails from "../GetInvolved/Employment/jobDetails";

const BodyContainer = () => {
    const location = useLocation()
    const id = location.pathname.split(`/`).at(-1)
    return (
        <Col className={`main-structure-pages`}>
            <Routes>
                <Route path={`/`} element={<HomePage/>}/>
                <Route path={`/about_us`} element={<AboutUs/>}/>
                <Route path={`/contact_us`} element={<ContactUs/>}/>
                <Route path={`/gallery`} element={<Gallery/>}/>
                <Route path={`/get_involved`} element={<GetInvolved/>}/>
                <Route path={`/get_involved/donation`} element={<Donation/>}/>
                <Route path={`/get_involved/donation/success`} element={<DonationSuccess/>}/>
                <Route path={`/get_involved/lay_spiritan`} element={<LaySpiritan/>}/>
                <Route path={`/get_involved/fundraising`} element={<UnderConstruction/>}/>
                <Route path={`/get_involved/employment`} element={<Employment/>}/>
                <Route path={`/get_involved/employment/${id}`} element={<JobDetails/>}/>
                <Route path={`/get_involved/volunteer`} element={<Volunteer/>}/>
                <Route path={`/what_we_do`} element={<WhatWeDo/>}/>
                <Route path={`/what_we_do/parishes`} element={<Parishes/>}/>
                <Route path={`/what_we_do/education`} element={<Education/>}/>
                <Route path={`/resources`} element={<Resources/>}/>
                <Route path={`/resources/spiritan_rule_of_life`} element={<RuleOfLife/>}/>
                <Route path={`/resources/community_life`} element={<CommunityLife/>}/>
                <Route path={`/blogs_&_news/`} element={<BlogsNews/>}/>
                <Route path={`/blogs_&_news/${id}`} element={<BlogDetails/>}/>
                <Route path={`/upcoming_events/`} element={<UpcomingEvents/>}/>
                <Route path={`/upcoming_events/${id}`} element={<UpcomingEvents/>}/>


            </Routes>
        </Col>
    );
};

export default BodyContainer;