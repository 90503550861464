import React, {useEffect, useState} from 'react'
import HeroSection from "./heroSection";
import TestimonySection from "./testimonySection";
import SecondCtaSection from "./secondCTASection";
import ApiCrud from "../../API/services";
import {IHomePage} from "./interfaces";
import {LoadingSpinner} from "../../Components/Atoms/LoadingSpin";

const HomePageContainer = () => {
    const [data, setData] = useState<IHomePage | undefined>()

    useEffect(() => {
        const AbortRequest = new AbortController()
        const {getAll} = ApiCrud({endpoint: `home/`, signal: AbortRequest.signal})
        !data && getAll()
            .then((res) => {
                console.log('request done')
                setData(res.data.items[0])
            })
            .catch((err) => {
                console.log('err', err)
                if (err.code === 'ERR_CANCELED') {
                    return
                }

            })

        return () => {
            console.log('aborted')
            setData(undefined)
            AbortRequest.abort()
        }
    }, [])
    return (
        <div className={'page home-page-container'}>
            {data ?
                <>
                    <HeroSection {...data}/>
                    <TestimonySection {...data}/>
                    <SecondCtaSection/>
                </>
                :
                <section className="home-section home-hero-section">
                    <LoadingSpinner/>
                </section>
            }

        </div>
    )
}

export default HomePageContainer
