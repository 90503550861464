import React from 'react';
import UnderConstruction from '../../Assets/Images/page_under_construction.png'

const UnderConstructionContainer = () => {
    return (
        <div className={'page under-construction-container'}>
            <img src={UnderConstruction} alt="under construction"/>
        </div>
    );
};

export default UnderConstructionContainer;