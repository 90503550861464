import React from 'react';
import MassInfoComponent from "./massInfoComponent";
import ContactLinksComponent from "./contactLinksComponent";
import {IParishTabComponent} from "./interfaces";
import PriestsComponent from "./priestsComponent";
import ParishInfoComponent from "./parishInfoComponent";

const ParishTabComponent = ({tabTitles, parishes, activeTab}: IParishTabComponent) => {
    return (
        <>
            {tabTitles?.map((tabTitle) => {
                return (
                    <>
                        {tabTitle.country === activeTab &&
                            <>
                                {parishes?.map((data, index) => {
                                    return (
                                        <>
                                            {data.state === tabTitle.state && data.country === tabTitle.country &&
                                                <div
                                                    className="parish-tab-content"
                                                    key={'parish-state-' + index.toString()}
                                                    style={{backgroundImage: `url(${data.parish.image?.url})`}}
                                                    // style={{backgroundImage: `url(${MainURL + data.parish.image?.url})`}}
                                                    id={data.parish.name + (data.parish.addresses.length > 0 && data.parish.addresses[0].city !== 'unknown' && ' ' + data.parish.addresses[0].city)}
                                                >
                                                    <div className={'parish-tab-content-title'}>
                                                        <h1>{tabTitle.state}</h1>
                                                    </div>
                                                    <div className="parish-tab-content-body">
                                                        <>
                                                            {data?.country === tabTitle.country && data?.state === tabTitle.state &&
                                                                <div key={index} className="parish-card">
                                                                    <div className="parish-card-title">
                                                                        <span className={'parish-card-title-left'}>
                                                                        <h1>
                                                                            {data.parish.name},&nbsp;
                                                                            <big>
                                                                                {data.parish.addresses.length > 0 && data.parish.addresses[0].city !== 'unknown' && data.parish.addresses[0].city}
                                                                            </big>
                                                                        </h1>
                                                                    </span>
                                                                        <div className={'parish-card-title-right'}>
                                                                            <h1>{tabTitle.state}</h1>
                                                                        </div>
                                                                    </div>
                                                                    <div className="parish-card-body">
                                                                        <>
                                                                            <PriestsComponent
                                                                                heads={data.parish.heads}/>
                                                                        </>
                                                                        <>
                                                                            {data.parish.mass_infos?.length > 0 &&
                                                                                <MassInfoComponent
                                                                                    massInfos={data.parish.mass_infos}/>
                                                                            }
                                                                        </>
                                                                        <>
                                                                            {(data.parish.emails.length > 0 || data.parish.phones.length > 0 || data.parish.social_media.length > 0) &&
                                                                                <ContactLinksComponent
                                                                                    emails={data.parish.emails}
                                                                                    phones={data.parish.phones}
                                                                                    social_media={data.parish.social_media}
                                                                                    address={data.parish.addresses}
                                                                                />
                                                                            }
                                                                        </>
                                                                        <>
                                                                            {data.parish.description &&
                                                                                <ParishInfoComponent
                                                                                    info={data.parish.description}
                                                                                />
                                                                            }
                                                                        </>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )
                                })}
                            </>
                        }
                    </>
                )
            })}
        </>
    );
};

export default ParishTabComponent;