import {useEffect, useState} from "react";

const getWindowSize = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
    };
};

export const useGetWindowDimensions = () => {
    // Initialize the state with the current window size
    const [windowSize, setWindowSize] = useState(getWindowSize());

    // A function that updates the state with the new window size
    const handleResize = () => {
        setWindowSize(getWindowSize());
    };

    // Add an event listener for the resize event in the useEffect hook
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        // Remove the event listener in the cleanup function
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Run only once on mount

    // Return the window size state
    return windowSize;
}