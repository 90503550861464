import {Link} from "react-router-dom";
// import {blogs} from "../../../API/fakeData";
import React, {useEffect, useState} from "react";
import ApiCrud from "../../../API/services";
import {IBlog} from "./interfaces";
import {LoadingSpinner} from "../../../Components/Atoms/LoadingSpin";

const BlogsContainer = () => {
    const [blogs, setBlogs] = useState<IBlog[]>()

    useEffect(() => {
        const AbortRequest = new AbortController()
        const {getAll} = ApiCrud({endpoint: `blogs/`, signal: AbortRequest.signal})
        getAll()
            .then((res) => {
                return (
                    setBlogs(res.data.items)
                )
            })
            .catch((err) => {
                if (err.code === 'ERR_CANCELED') {
                    return
                }
            })

        return () => {
            setBlogs(undefined)
            AbortRequest.abort()
        }
    }, []);

    return (
        <>
            {
                blogs ?
                    <>
                        <div className="page page-padding blogs-container">
                            {
                                blogs.slice(0, 4).map((blog, index) => {
                                    return (
                                        <>
                                            {index === 0 ?
                                                <div className="blog recent" key={blog.id}>
                                                    <div className="recent-blog-content">
                                                        <div className="recent-blog-header" title={blog.title}>
                                                            <h3>{blog.title}</h3>
                                                            <div className="recent-blog-header-extras">
                                                                <span><big>{blog.author.name}</big></span>
                                                                <pre> . </pre>
                                                                <span
                                                                    className="blog-date"><big>{blog.date}</big></span>
                                                            </div>
                                                        </div>
                                                        <div className="recent-blog-body">
                                                            <div
                                                                className={`about-us-content`}
                                                                dangerouslySetInnerHTML={{__html: blog.content}}
                                                            />
                                                            <p>
                                                                <Link to={blog.id}>keep reading</Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="blog-image">
                                                        <img src={blog.image.url} alt={blog.title}/>
                                                        {/*<img src={MainURL + blog.image.url} alt={blog.title}/>*/}
                                                    </div>
                                                </div>
                                                :
                                                <div className={`blog older${index}`} key={blog.id} onClick={() => {
                                                    document.getElementById(`blog${blog.id}`)?.click()
                                                }}>
                                                    <Link to={blog.id} style={{display: 'none'}}
                                                          id={`blog${blog.id}`}></Link>
                                                    <div className="blog-content">
                                                        <h3 className="blog-title" title={blog.title}>
                                                            {blog.title}
                                                        </h3>
                                                        <div className="blog-extras">
                                                            <small>{blog.author.name}</small>
                                                            <small>{blog.date}</small>
                                                        </div>
                                                    </div>
                                                    <div className="blog-image">
                                                        <img src={blog.image.url} alt={blog.title}/>
                                                        {/*<img src={MainURL + blog.image.url} alt={blog.title}/>*/}
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>
                        <div className="page-padding older-blogs">
                            {
                                blogs.slice(4).map((blog, index) => {
                                    return (
                                        <div className={`older-blog-card`} key={blog.id} onClick={() => {
                                            document.getElementById(`blog${blog.id}`)?.click()
                                        }}>
                                            <Link to={blog.id} style={{display: 'none'}} id={`blog${blog.id}`}></Link>
                                            <div className="older-blog-content">
                                                <h3 className="older-blog-title" title={blog.title}>
                                                    {blog.title}
                                                </h3>
                                                <div className="older-blog-extras">
                                                    <small>{blog.author.name}</small>
                                                    <small>{blog.date}</small>
                                                </div>
                                                <div className="older-blog-body">
                                                    <div
                                                        className={`about-us-content`}
                                                        dangerouslySetInnerHTML={{__html: blog.content}}/>
                                                </div>
                                            </div>
                                            <div className="older-blog-image">
                                                <img src={blog.image.url} alt={blog.title}/>
                                                {/*<img src={MainURL + blog.image.url} alt={blog.title}/>*/}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                    :
                    <div className={"page loading-spinner"}>
                        <LoadingSpinner/>
                    </div>
            }
        </>
    )
}
export default BlogsContainer