export const CapitalizeFirstLetter = (word: string) => {
    let newWord = word.toLowerCase()
    if (newWord.charAt(0) === '/') {
        newWord = newWord.slice(1)
    }
    return newWord.charAt(0).toUpperCase() + newWord.slice(1);
}

export const LabelMaker = (phrase: string, separator: string) => {
    const words = phrase.split(separator)
    const capitalizedWords = words.map((word) => CapitalizeFirstLetter(word))
    let newPhrase: string = capitalizedWords.join(" ")
    return newPhrase
}

export const articleSelector = (name: string) => {
    let newName: string
    const vowels = ['a', 'e', 'i', 'u', 'o']
    if (vowels.includes(name.charAt(0)))
        newName = `an ${name}`
    else
        newName = `a ${name}`

    return newName
}