import React from 'react';
import {Navbar} from "../../Components/Templates/Navbar";
import {Col, Container, Row} from "react-bootstrap";
import {Body} from "./index";
import {INavbarButton} from "../../Components/Templates/Navbar/interfaces";
import Logo from '../../Assets/Images/logo.svg'

const MainStructureContainer = () => {
    const NavbarButtons: INavbarButton[] = [
        {name: 'Home', link: '/'},
        {name: 'About Us', link: '/about_us'},
        {name: 'What We Do', link: '/what_we_do'},
        {name: 'Resources', link: '/resources'},
        {name: 'Blogs & News', link: '/blogs_&_news'},
        {name: 'Upcoming Events', link: '/upcoming_events'},
        {name: 'Get Involved', link: '/get_involved'},
        {name: 'Gallery', link: '/gallery'},
        {name: 'Contact Us', link: '/contact_us'},
    ]
    return (
        <Container className={'main-structure-container'} fluid={true}>
            <Row className={'main-structure-header'}>
                <Navbar
                    buttons={NavbarButtons}
                    brandImageUrl={Logo}
                />
            </Row>
            <Row className={'main-structure-body'}>
                <Col className={'main-structure-main'}>
                    <Body/>
                </Col>
            </Row>
        </Container>
    );
};

export default MainStructureContainer;