import React from 'react';
import {ThemeProvider} from 'react-bootstrap';
import './App.css';
import './styles.scss';
import {MainPage} from "./Pages/MainStructure";


function App() {

    // const [screenWidth, setScreenWidth]= useState(window.innerWidth)
    // const [styleSheet, setStyleSheet] = useState('styles')
    // useEffect(()=>{
    //     if(screenWidth < 600){
    //         setStyleSheet('mobileStyles')
    //         console.log('mobile styles')
    //     }else if (screenWidth > 600 && screenWidth <= 1000){
    //         setStyleSheet('tabletStyles')
    //         console.log('tablet styles')
    //     }
    // })
    // import(`./${styleSheet}.scss`)

    // useEffect(()=>{
    //     function handleResize() {
    //         console.log(screenWidth)
    //         setScreenWidth(window.innerWidth);
    //     }
    //
    //     window.addEventListener('resize', handleResize);
    //
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // },[])
    return (
        <div className="App">
            <ThemeProvider
                breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
                minBreakpoint="xxs"
            >
                <MainPage/>
            </ThemeProvider>
        </div>
    );
}

export default App;
