import React, {useState} from "react";
import MediaTab from "./Tabs/mediaTab";
import './styles.scss'
import {TGalleryTabs} from "./interfaces";

const GalleryTabsContainer: React.FC = () => {
    const [activeTab, setActiveTab] = useState<TGalleryTabs | undefined>("images");


    return (
        <div className={"page page-padding gallery-tabs-container"}>
            <div className="mb-3 gallery-tabs">
                <div
                    className={`gallery-nav-link ${activeTab === 'images' ? "active" : ''}`}
                    onClick={() => {
                        setActiveTab(undefined)
                        setActiveTab("images")
                    }}
                >
                    Images
                </div>
                <div
                    className={`gallery-nav-link ${activeTab === 'videos' ? "active" : ''}`}
                    onClick={() => {
                        setActiveTab(undefined)
                        setActiveTab("videos")
                    }}
                >
                    Videos
                </div>
            </div>
            <div className="gallery-tabs-body">
                <div className="gallery-tabs-tab">
                    <MediaTab
                        type={activeTab!}
                    />
                </div>
            </div>
        </div>
    );
};

export default GalleryTabsContainer;