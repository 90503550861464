import React, {useEffect, useState} from 'react';
import ApiCrud from "../../API/services";
import {AboutUsSection} from './aboutUsSectionContainer';
import {LeaderCard} from './leaderCardContainer';
import {navbarBackgroundChanger} from "../../Helpers/navbarColorHelper";
import {LoadingSpinner} from "../../Components/Atoms/LoadingSpin";


const AboutUsContainer = () => {
    const [info, setInfo] = useState<any>()

    const margin: "left" | "right" = info?.headers.map((header: any, index: number) => {
        return (index % 2 === 0 ? "left" : "right")
    })

    useEffect(() => {
        const AbortRequest = new AbortController()
        const {getAll} = ApiCrud({endpoint: 'info/about_us/', signal: AbortRequest.signal})
        !info && getAll().then((res) => {
            setInfo(res.data.items[0]);
        })
            .catch((err) => {
                if (err.code === 'ERR_CANCELED') {
                    return
                }

            })


        return () => {
            setInfo(undefined)
            AbortRequest.abort()
        }
    }, [])

    useEffect(() => {
        navbarBackgroundChanger('transparent')
        return () => {
            navbarBackgroundChanger('var(--body-background)')
        }

    }, []);

    return (
        <>
            {info ?
                <div className={"about-us-container"}>

                    <div className="about-us-body">
                        <div id={'home'} className={"about-us-hero-section about-us-section"}
                             style={{backgroundImage: `url(${info.background_image.url})`}}>
                            {/*style={{backgroundImage: `url(${MainURL + info.background_image.url})`}}>*/}
                            <div className="hero-section-title">
                                <h1>{info.name}</h1>
                            </div>
                            <div className="hero-section-motto">
                                <big>{info.motto}</big>
                            </div>
                        </div>
                        {
                            info.headers.map((header: any, index: number) => {
                                return (
                                    AboutUsSection(header, index, margin)
                                )
                            })
                        }
                        <div id={'leaders'} className={"page-padding about-us-leaders-section about-us-section"}>
                            <div className="about-us-section-title leaders">
                                <h1>Our Leaders</h1>
                            </div>
                            <div className="leaders-section-body">
                                {
                                    info.leaders.map((leader: any) => {
                                        return (
                                            LeaderCard(leader)
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={"page loading-spinner"}>
                    <LoadingSpinner/>
                </div>
            }
        </>
    );
};

export default AboutUsContainer;



