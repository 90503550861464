import React, {useEffect, useState} from 'react';
import ApiCrud from "../../API/services";
import {LabelMaker} from "../../Helpers/textHelpers";
import {Button, Col, Form, Row} from 'react-bootstrap';
import {HiLocationMarker, HiMail} from "react-icons/hi";
import {HiPhone} from "react-icons/hi2";
import {navbarBackgroundChanger} from "../../Helpers/navbarColorHelper";
import {LoadingSpinner} from "../../Components/Atoms/LoadingSpin";

const ContactUsContainer = () => {
    const [info, setInfo] = useState<any>()
    const [values, setValues] = useState<any>({
        request_type: 'contact_us',
        sender: '',
        email: '',
        content: '',
        subject: ''
    })
    const [validated, setValidated] = useState(false);
    const [message, setMessage] = useState<{ text: string, type: string }>()

    const onChangeHandler = (e: any) => {
        setValues((curr: any) => {
            return (
                curr ?
                    {...curr, [e.target.name]: e.target.value ?? e.target.innerText}
                    :
                    {[e.target.name]: e.target.value ?? e.target.innerText}
            )
        })
    }

    useEffect(() => {
        const AbortRequest = new AbortController()
        const {getAll} = ApiCrud({endpoint: 'contact_us/', signal: AbortRequest.signal})
        !info && getAll().then((res) => {
            setInfo(res.data.items[0]);
        })
            .catch((err) => {
                if (err.code === 'ERR_CANCELED') {
                    return
                }

            })


        return () => {
            setInfo(undefined)
            AbortRequest.abort()
        }
    }, [])


    const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const {create} = ApiCrud({endpoint: 'requests/', data: values})
        create().then(() => {
            setValidated(false);
            setValues({request_type: 'contact_us', sender: '', email: '', content: '', subject: ''})
            setMessage({text: 'Sent!', type: 'success'})
        })
            .catch(() => {
                setMessage({text: 'Failed!', type: 'failed'})
            })
        setValidated(true);
    }
    useEffect(() => {
        navbarBackgroundChanger('transparent')
        return () => {
            navbarBackgroundChanger('var(--body-background)')
        }

    }, []);

    useEffect(() => {
        setTimeout(() => setMessage(undefined), 3000)
    }, [message])

    return (
        <>
            {
                info ?
                    <div className={"page contact-us-container"}
                         style={{backgroundImage: `url(${info.address.image.url})`}}>
                        {/*style={{backgroundImage: `url(${MainURL + info.address.image.url})`}}>*/}
                        {message && <div className={`message ${message.type}`}>{message.text}</div>}
                        <div className="contact-us-body">
                            <div className={"contact-us-form-container"}>
                                <div className="section-header">
                                    <h1>{LabelMaker("let's have a talk", " ")}</h1>
                                </div>
                                <Form
                                    className={"contact-us-form"}
                                    onSubmit={(values) => onSubmitHandler(values)}
                                    noValidate
                                    validated={validated}
                                >
                                    <Form.Group className="mb-3 contact-us-form-group">
                                        <Form.Label>Full Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="your full name"
                                            name={'sender'}
                                            value={values['sender']}
                                            onChange={(e) => onChangeHandler(e)}
                                            required
                                        />
                                </Form.Group>
                                <Form.Group className="mb-3 contact-us-form-group" controlId="formBasicEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="your email address"
                                        name={'email'}
                                        value={values['email']}
                                        onChange={(e) => onChangeHandler(e)}
                                        required
                                    />
                                    <Form.Text className="text-muted">
                                        We'll never share your email
                                    </Form.Text>
                                </Form.Group>
                                    <Form.Group className="mb-3 contact-us-form-group">
                                        <Form.Label>Subject</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="subject"
                                            name={'subject'}
                                            value={values['subject']}
                                            onChange={(e) => onChangeHandler(e)}
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3 contact-us-form-group">
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="message"
                                            name={'content'}
                                            value={values['content']}
                                            onChange={(e) => onChangeHandler(e)}
                                            required
                                        />
                                </Form.Group>
                                <Form.Group className="mb-3 contact-us-form-group">
                                    <Form.Check
                                        label="Is this a prayer request?"
                                        defaultChecked={values['request_type'] === 'prayer'}
                                        onChange={(e) => {
                                            e.target.checked ?
                                                setValues((curr: any) => {
                                                    return (
                                                        {...curr, ['request_type']: 'prayer'}
                                                    )
                                                })
                                                :
                                                setValues((curr: any) => {
                                                    return (
                                                        {...curr, ['request_type']: 'contact_us'}
                                                    )
                                                })
                                        }}
                                    />
                                </Form.Group>
                                    <Button variant="primary" type="submit">
                                        Submit
                                    </Button>
                                </Form>
                            </div>
                            <div className="contact-us-info">
                                <div className="section-header">
                                    <h1>{LabelMaker("or, reach us here", " ")}</h1>
                                </div>
                                <div className="sections">
                                    <Row className="phone section">
                                        <Col md={{span: 2}} className={"icon"}><HiPhone/></Col>
                                        <Col md={{span: 10}} className={"text"}>
                                            <a href={`tel:${info.phone.phone_number}`}>
                                                {info.phone.phone_number}
                                            </a>
                                        </Col>
                                    </Row>
                                    <Row className="email section">
                                        <Col md={{span: 2}} className={"icon"}><HiMail/></Col>
                                        <Col md={{span: 10}} className={"text"}>
                                            <a href={`mailto:${info.email.email_address}`}>
                                                {info.email.email_address}
                                            </a>
                                        </Col>
                                    </Row>
                                    <Row className="address section">
                                        <Col md={{span: 2}} className={"icon"}><HiLocationMarker/></Col>
                                        <Col md={{span: 10}} className={"text"}>
                                            <a target={'/'}
                                               href={`https://www.google.com/maps/place/${info.address.address.replaceAll(' ', '+')}`}>
                                                {info.address.address}
                                            </a>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={"page loading-spinner"}>
                        <LoadingSpinner/>
                    </div>
            }
        </>

    );
};

export default ContactUsContainer;