import React, {useEffect, useState} from 'react';
import {DonateButton} from "../../Components/Atoms/DonateButton";
import {Link} from "react-router-dom";
import ApiCrud from "../../API/services";
import {IGetInvolvedResponse} from "./interface";
import {LoadingSpinner} from "../../Components/Atoms/LoadingSpin";

const GetInvolvedContainer = () => {
    const [data, setData] = useState<IGetInvolvedResponse[] | undefined>()

    useEffect(() => {
        const AbortRequest = new AbortController()
        const {getAll} = ApiCrud({endpoint: `get_involved/`, signal: AbortRequest.signal})
        !data && getAll()
            .then((res) => {
                return (
                    setData(res.data.items)
                )
            })
            .catch((err) => {
                if (err.code === 'ERR_CANCELED') {
                    return
                }

            })

        return () => {
            setData(undefined)
            AbortRequest.abort()
        }
    }, [])

    const volunteerSection = data && data.filter((section) => section.order === 1)[0]
    const laySpiritanSection = data && data.filter((section) => section.order === 2)[0]
    const employmentSection = data && data.filter((section) => section.order === 3)[0]
    const donationSection = data && data.filter((section) => section.order === 4)[0]
    const fundraisingSection = data && data.filter((section) => section.order === 5)[0]

    return (
        <>
            {data ?
                <div className={'page page-padding get-involved-container'}>
                    <div className="get-involved-body">
                        <div className="get-involved-volunteer get-involved-body-section">
                            <h3>{volunteerSection?.title}</h3>
                            <big>
                                {volunteerSection?.text}
                            </big>
                            <Link to={'volunteer'}>{volunteerSection?.button_name}</Link>
                        </div>
                        <div className="get-involved-lay get-involved-body-section">
                            <h3>{laySpiritanSection?.title}</h3>
                            <big>
                                {laySpiritanSection?.text}
                            </big>
                            <Link to={'lay_spiritan'}>{laySpiritanSection?.button_name}</Link>
                        </div>
                        <div className="get-involved-employment get-involved-body-section">
                            <h3>{employmentSection?.title}</h3>
                            <big>
                                {employmentSection?.text}
                            </big>
                            <Link to={'employment'}>{employmentSection?.button_name}</Link>
                        </div>
                        <div className="get-involved-donation get-involved-body-section">
                            <h3>{donationSection?.title}</h3>
                            <big>
                                {donationSection?.text}
                            </big>
                            <DonateButton endpoint={'donation/create-checkout-session/'}/>
                        </div>
                        <div className="get-involved-fund get-involved-body-section">
                            <h3>{fundraisingSection?.title}</h3>
                            <big>
                                {fundraisingSection?.text}
                            </big>
                            <Link to={'lay_spiritan'}>{fundraisingSection?.button_name}</Link>
                        </div>
                    </div>
                </div>
                :
                <div className={"page loading-spinner"}>
                    <LoadingSpinner/>
                </div>
            }
        </>
    );
};

export default GetInvolvedContainer;