import React, {useEffect} from 'react';
import PrayerRequest from "./prayerRequestContainer";
import RuleOfLife from '../../Assets/Images/rule-of-life.jpg'
import CommunityLife from '../../Assets/Images/community-life.jpg'
import PrayerRequestBG from '../../Assets/Images/prayer-request.jpeg'
import {navbarBackgroundChanger} from "../../Helpers/navbarColorHelper";
import {Link} from "react-router-dom";

const ResourcesContainer = () => {
    useEffect(() => {
        navbarBackgroundChanger('transparent')
        return () => {
            navbarBackgroundChanger('var(--body-background)')
        }

    }, []);
    return (
        <div className="resources-container page">
            <div className="prayer-request">
                <div className="resources-section-image">
                    <img src={`${PrayerRequestBG}`} alt=""/>
                </div>
                <PrayerRequest/>
            </div>
            <div className="resources-section spiritan-rule-of-life">
                <div className="resources-section-image">
                    <img src={`${RuleOfLife}`} alt=""/>
                </div>
                <div className="resources-section-header">
                    <h1>Spiritan Rule Of Life</h1>
                </div>
                <div className="resources-section-body">
                    <p className="quote resources-section-text">
                        The Spirit of the Lord is on me,
                        for He has anointed me,
                        to bring the good news to the afflicted.
                        He has sent me
                        to proclaim liberty to captives,
                        sight to the blind,
                        to let the oppressed, go free,
                        to proclaim a year of favour from the Lord
                    </p>
                    <small className={'quote-like'}>(Luke 4:18-19)</small>
                </div>
                <Link to={'/resources/spiritan_rule_of_life'}>read more...</Link>
            </div>
            <div className="resources-section community-life">
                <div className="resources-section-image">
                    <img src={`${CommunityLife}`} alt=""/>
                </div>
                <div className="resources-section-header">
                    <h1>Community Life</h1>
                </div>
                <div className="resources-section-body">
                    <p className="quote resources-section-text">
                        The whole group of believers was united,
                        heart and soul; no one claimed private ownership of any possessions, as every-thing they
                        owned was held in common” (Acts 4:32).
                        “As the chosen of God, then, … you are to be
                        clothed in heartfelt compassion, in generosity.
                        and humility, gentleness and patience. Bear
                        with one another; forgive each other if one of
                        you have a complaint against another. The
                        Lord has forgiven you; now you must do the
                        same. Over all these clothes, put on love, the
                        perfect bond
                    </p>
                    <small className={'quote-like'}>(Colossians 3:12-14)</small>
                </div>
                <Link to={'/resources/community_life'}>read more...</Link>
            </div>
        </div>
    );
};

export default ResourcesContainer;