import React from 'react';

const CommunityLife = () => {
    return (
        <div className={"community-life-container page page-padding"}>
            <div className="community-life-body">
                <div className="community-life-text">
                    <p className="quote">
                        The whole group of believers was united,
                        heart and soul; no one claimed private ownership of any possessions, as every-thing they
                        owned was held in common” (Acts 4:32).
                        “As the chosen of God, then, … you are to be
                        clothed in heartfelt compassion, in generosity.
                        and humility, gentleness and patience. Bear
                        with one another; forgive each other if one of
                        you have a complaint against another. The
                        Lord has forgiven you; now you must do the
                        same. Over all these clothes, put on love, the
                        perfect bond
                    </p>
                    <small className={'quote-like'}>(Colossians 3:12-14)</small>
                </div>


                <h3>CALLED TO LIVE IN COMMUNITY</h3>

                <p className={'community-life-text'}>
                    We have been called in Christ to live out our Spiritan calling in community.
                    <p className="quote">
                        To bring the apostolatic life – for which Spiritan life is intended – to its perfection,
                        to assure the continuance and the development of the works in which it engages and to foster the
                        holiness of its members,
                        the Congregation has adopted life in community as its founding principle. Its members shall all
                        live in community at all times
                    </p>
                    <small className="quote-like">(Rule of 1849; N.D. X, 454).</small>
                </p>
                <p className="community-life-text">
                    Community life is then an essential element in the Spiritan way of life.
                    It is a privileged means of practising the evangelical counsels in the service of the Good News.
                </p>
                <p className="community-life-text">
                    By virtue of our calling, we are at the service of the Church, whose mission it is
                    <p className="quote">
                        to gather together into one the scattered children of God
                    </p>
                    <small className="quote-like">(John 11:52)</small>.
                    Fraternal life in community builds up the body of Christ.
                    It is living proof that a genuine fraternal unity in Christ is possible.
                </p>
            </div>
        </div>
    );
};

export default CommunityLife;