import React from 'react';
import UnderConstruction from '../../../Assets/Images/page_under_construction.png'

const EducationContainer = () => {
    return (
        <div className={'page education-container'}>
            <img src={UnderConstruction} alt="under construction"/>
        </div>
    );
};

export default EducationContainer;