import React from 'react';
import {Link} from "react-router-dom";

const SecondCtaSection = () => {
    return (
        <section className="home-section home-extra page page-padding">
            <h1>You would have been one of them!</h1>
            <Link to={'/get_involved'} className="hero-cta">
                give us a hand!
            </Link>
        </section>
    );
};

export default SecondCtaSection;