import React from 'react';
import {IParishComponents} from "./interfaces";

const PriestsComponent = ({heads}: IParishComponents) => {
    return (
        <span
            className="parish-card-content parish-card-heads">
            <h3>{heads && heads.length > 1 ? 'Priests' : "Priest"}</h3>
            <span>
                {heads && heads.map((head, index) => {
                    return (
                        <div className="parish-card-content-line priest-info">
                            <big>{head.name} </big> &nbsp;
                            {head.position !== 'null' &&
                                <small>({head.position})</small>
                            }
                        </div>
                    )
                })}
            </span>
        </span>
    );
};

export default PriestsComponent;