import React from 'react';
import {IParishComponents} from "./interfaces";

const ContactLinksComponent = ({emails, phones, social_media, address}: IParishComponents) => {
    return (
        <span className={'parish-card-content parish-contact-info'}>
            <h3>Contact Info</h3>
            <span>
                {emails && emails.length > 0 &&
                    <div className={'parish-card-content-line'}>
                        <big>
                            Email:
                        </big>
                        <small>

                            <a href={`mailto:${emails[0].email_address}`}>
                                {emails[0].email_address}
                            </a>
                        </small>
                    </div>
                }
                {phones && phones.length > 0 &&
                    <div className={'parish-card-content-line'}>
                        <big>
                            phone:
                        </big>
                        <small>
                            <a href={`tel:${phones[0].phone_number}`}>
                                {phones[0].phone_number}
                            </a>
                        </small>
                    </div>
                }
                {social_media && social_media.length > 0 &&
                    <div className={'parish-card-content-line'}>
                        <big>
                            Social Media:
                        </big>
                        <small>
                            {social_media[0].name}
                        </small>
                    </div>
                }
                {address && address.length > 0 &&
                    <div className={'parish-card-content-line'}>
                        <big>
                            Address:
                        </big>
                        <small>
                            <a target={'/'}
                               href={`https://www.google.com/maps/place/${address[0].address.replaceAll(' ', '+')}`}>
                                {address[0].building_number + ' ' + address[0].street_name + ' ' + address[0].city}
                            </a>
                        </small>
                    </div>
                }
            </span>
        </span>
    );
};

export default ContactLinksComponent;