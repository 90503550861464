import React from 'react';
import './styles.scss'
import {IDonateButton} from "./interfaces";
import ApiCrud from "../../../API/services";
import {Button} from 'react-bootstrap';
import {useLocation} from "react-router";


const DonateButtonContainer = ({endpoint, ...props}: IDonateButton) => {
    const location = useLocation()
    const HandleSubmit = () => {
        const {create} = ApiCrud({endpoint: endpoint, data: {"cancel_url": location.pathname}})
        create().then(res => {
            const data = res.data; // parse JSON data
            window.location.href = data.url; // redirect to URL
        })
    }
    return (
        <div className={'donate-button-container'}>
            <Button
                className={'donate-button'}
                onClick={HandleSubmit}
                {...props}
            >
                donate now
            </Button>
        </div>
    );
};

export default DonateButtonContainer;