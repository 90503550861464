import React from 'react';

const DonationSuccessContainer = () => {
    return (
        <div className={'page page-padding donation-success-container'}>
            <h1>Donation received successfully, thank you for your generosity</h1>
        </div>
    );
};

export default DonationSuccessContainer;