import React, {useEffect, useState} from 'react';
import ApiCrud from "../../../API/services";
import {IVolunteerValues} from "./volunteerContainer";
import {Button, Form} from "react-bootstrap";
import {IJoinForm} from "./interfaces";
import {LabelMaker} from "../../../Helpers/textHelpers";

const JoinForm = ({hasFieldsOfHelp, endPoint, submitButtonName, fieldsOfHelp}: IJoinForm) => {
    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState<IVolunteerValues>({
        full_name: '',
        phone: '',
        email: '',
        note: '',
        fields: undefined
    })
    const [fields, setFields] = useState<string[]>()
    const [message, setMessage] = useState<{ text: string, type: string }>()

    useEffect(() => {
        setTimeout(() => setMessage(undefined), 5000)
    }, [message])


    const onChangeHandler = (e: any) => {
        setValues((curr: any) => {
            return (
                curr ?
                    {...curr, [e.target.name]: e.target.value ?? e.target.innerText}
                    :
                    {[e.target.name]: e.target.value ?? e.target.innerText}
            )
        })
    }
    const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const newData = {...values}
        const {create} = ApiCrud({endpoint: endPoint, data: newData})
        create().then(() => {
            setValidated(false);
            setValues({full_name: '', phone: '', email: '', note: '', fields: undefined})
            setMessage({text: 'Thank you!', type: 'success'})
            setFields(undefined)
        })
            .catch((res) => {
                setMessage({text: res, type: 'failed'})
            })
        setValidated(true);
    }

    const onCheckHandler = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        if (e.target.checked) {
            setFields((curr) => curr ? [...curr, id] : [id])
        } else {
            let index = fields?.indexOf(id)
            index ?
                setFields((curr) => {
                    return (curr ? curr.filter((item) => item !== id) : undefined)
                })
                :
                setFields(undefined)
        }
        console.log(fields)
    }


    useEffect(() => {
        setValues(curr =>
            curr ?
                {...curr, fields: fields}
                :
                {fields: fields}
        )
    }, [fields])

    return (
        <Form
            className={"contact-us-form"}
            onSubmit={(values) => onSubmitHandler(values)}
            noValidate
            validated={validated}
        >
            {message && <div className={`message ${message.type}`}>{message.text}</div>}
            <Form.Group className="mb-3 contact-us-form-group">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="your full name"
                    name={'full_name'}
                    value={values['full_name']}
                    onChange={(e) => onChangeHandler(e)}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3 contact-us-form-group" controlId="formBasicPhone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                    type="phone"
                    placeholder="your phone number"
                    name={'phone'}
                    value={values['phone']}
                    onChange={(e) => onChangeHandler(e)}
                    required
                />
                <Form.Text className="text-muted">
                    We'll never share your number
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3 contact-us-form-group" controlId="formBasicEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="your email address"
                    name={'email'}
                    value={values['email']}
                    onChange={(e) => onChangeHandler(e)}
                    required
                />
                <Form.Text className="text-muted">
                    We'll never share your email
                </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3 contact-us-form-group">
                <Form.Label>Note</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Note"
                    name={'note'}
                    value={values['note']}
                    onChange={(e) => onChangeHandler(e)}
                />
            </Form.Group>
            {hasFieldsOfHelp && fieldsOfHelp &&
                <Form.Group className="mb-3 contact-us-form-group">
                    <Form.Label>Fields</Form.Label>
                    {fieldsOfHelp.map((field: any, i: number) => {
                        if (field.is_open) {
                            return (
                                <Form.Check
                                    key={'fields_of_help_' + i}
                                    label={field.name}
                                    defaultChecked={false}
                                    checked={fields?.includes(field.id)}
                                    onChange={(e) => onCheckHandler(e, field.id)}
                                />
                            )
                        }
                        return (<></>)
                    })
                    }
                </Form.Group>
            }
            <Button variant="primary" type="submit">
                {LabelMaker(submitButtonName, '_')}
            </Button>
        </Form>
    );
};

export default JoinForm;