import React from 'react';
import {IJobCard} from "../interface";
import {Link} from "react-router-dom";

const JobCard = ({job}: IJobCard) => {
    return (
        <section className={'job-card-container'} onClick={() => {
            document.getElementById(`job${job.id}`)?.click()
        }}>
            <Link to={job.id} style={{display: 'none'}} id={`job${job.id}`}></Link>
            <h3>{job.title}</h3>
            <div className="job-card-sections">
                <div className={'job-card-date job-card-section'}>
                    <big>Posted</big>
                    <p>{job.date_posted}</p>
                </div>
                <div className={'job-card-onsite job-card-section'}>
                    <big>Location</big>
                    <p>{job.on_site ? `On Site/ ${job.location}` : 'Remotely'}</p>
                </div>
                <div className={'job-card-details job-card-section'}>
                    <big>Job Brief</big>
                    <p>{job.job_details}</p>
                </div>
            </div>
            {/*<div className={'job-card-responsibilities job-card-section'}><big>Your Responsibilities </big><p>{job.responsibilities}</p></div>*/}

            {/*<div className={'job-card-requirements job-card-section'}><big>Requirements</big><p>{job.requirements}</p></div>*/}
        </section>
    );
};

export default JobCard;