import React, {useEffect, useState} from 'react';
import ApiCrud from "../../../API/services";
import {IFormattedParish, IParish, IParishContainer, IParishTabTitle} from "./interfaces";
import ParishTabComponent from "./parishTabComponent";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {navbarBackgroundChanger} from "../../../Helpers/navbarColorHelper";
import {LoadingSpinner} from "../../../Components/Atoms/LoadingSpin";

const ParishesContainer = (props: IParishContainer) => {
    const [parishes, setParishes] = useState<IFormattedParish[]>()
    const [rawParishData, setRawParishData] = useState<IParish[]>()
    const [tabTitles, setTabTitles] = useState<IParishTabTitle[]>()
    const [activeTab, setActiveTab] = useState<string>()
    const [tabHeaders, setTabHeaders] = useState<string[]>()

    const {getAll} = ApiCrud({endpoint: 'contact_info/parishes/'})
    useEffect(() => {
        getAll().then(res => setRawParishData(res.data.items))
        navbarBackgroundChanger('transparent')
        return () => {
            navbarBackgroundChanger('var(--body-background)')
        }
    }, [])

    const getParishesTabs = (rawData: IParish[]) => {
        let countries: any[] = []
        let states: any[] = []
        let formattedParishes: IFormattedParish[] = []
        rawData?.map((parish, index) => {
            let country = parish.addresses[0]?.country
            let state = parish.addresses[0]?.state
            country && setTabTitles((curr) => {
                if (curr) {
                    if (curr.filter((item) =>
                        item.country === country && item.state === state
                    ).length > 0) {
                        return curr
                    } else {
                        return [...curr, {country: country, state: state}]
                    }
                } else {
                    return ([{
                        country: country,
                        state: state
                    }])
                }
            })

            if (country && !countries.includes(country)) {
                countries.push(country)
            }
            if (country && !states.includes(state)) {
                states.push(state)
            }
            formattedParishes.push({country: country, state: state, parish: parish})
        })
        setTabHeaders(countries.sort())
        setActiveTab(countries[0])
        setParishes(formattedParishes)
    }

    const handleNextCountry = () => {
        if (activeTab && tabHeaders) {
            if (tabHeaders?.indexOf(activeTab) < tabHeaders?.length - 1) {
                setActiveTab(tabHeaders[tabHeaders?.indexOf(activeTab) + 1])
            } else {
                setActiveTab(tabHeaders[0])
            }
        }
    }

    const handlePreviousCountry = () => {
        if (activeTab && tabHeaders) {
            if (tabHeaders?.indexOf(activeTab) - 1 >= 0) {
                setActiveTab(tabHeaders[tabHeaders?.indexOf(activeTab) - 1])
            } else {
                setActiveTab(tabHeaders[tabHeaders?.length - 1])
            }
        }
    }

    useEffect(() => {
        rawParishData && rawParishData.length > 0 && getParishesTabs(rawParishData)
    }, [rawParishData])

    return (
        <div className={'page parishes-container'}>
            {parishes ?
                <>
                    <div className={'parishes-navbar'}>
                        <div className="parishes-navbar-content">
                            <span className={'next-parish'} onClick={handlePreviousCountry}>
                                <IoIosArrowBack/>
                            </span>
                            <div className="parishes-navbar-countries">
                                {
                                    tabHeaders?.map((header, index) => {
                                        return (
                                            <div
                                                className={`tab-nav-link ${activeTab === header ? "active" : ''}`}
                                                onClick={() => setActiveTab(header)}
                                                key={"parish-header-" + index.toString()}
                                            >
                                                <h1>{header}</h1>
                                            </div>

                                        )
                                    })
                                }
                            </div>
                            <span className={'next-parish'} onClick={handleNextCountry}>
                                <IoIosArrowForward/>
                            </span>
                        </div>
                    </div>
                    {
                        (activeTab && tabTitles && tabTitles.length > 0) &&
                        <ParishTabComponent parishes={parishes} activeTab={activeTab} tabTitles={tabTitles}/>
                    }
                </>
                :
                <div className={"page loading-spinner"}>
                    <LoadingSpinner/>
                </div>
            }
        </div>
    )
}

export default ParishesContainer;