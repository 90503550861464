import axios from "axios";

export interface IApiCrud {
    endpoint: string,
    url?: string,
    data?: any,
    id?: string | number,
    headers?: any
    signal?: any
}

// export const MainURL = "http://192.168.1.5:8000"
// export const MainURL = "http://192.168.1.221:8000"
export const MainURL = "https://spiritian-backend.vercel.app"
// export const networkURL = "http://192.168.1.5:8000/api/v1/website/"
// export const networkURL = "http://192.168.1.221:8000/api/v1/website/"
export const networkURL = "https://spiritian-backend.vercel.app/api/v1/website/"

export const localURL = "http://127.0.0.1:8000/api/v1/website/"

const ApiCrud = ({endpoint, url, data, id, headers, signal}: IApiCrud) => {
    const baseURL = url ?? networkURL
    const axiosClient = axios.create({
        baseURL: baseURL,
        headers: headers ?? {
            'content-type': 'multipart/form-data'
        },
        signal: signal
    });
    const getAll = async () => {
        return await axiosClient.get(endpoint)
    }
    const get = async () => {
        if (id) {
            return await axiosClient.get(`${endpoint}${id}`)
        } else {
            return await axiosClient.get(`${endpoint}`)
        }
    }

    const create = async () => {
        return await axiosClient.post(endpoint, data)
    }
    const update = async () => {
        if (id === 0)
            return await axiosClient.post(endpoint, data)
        return await axiosClient.patch(`${endpoint}${id}`, data)
    }
    const deleteItem = async () => {
        return await axiosClient.delete(`${endpoint}${id}`)
    }

    return {
        getAll,
        create,
        get,
        update,
        deleteItem
    }
}
export default ApiCrud;
