import React from 'react';

const RuleOfLife = () => {
    return (
        <div className={'rule-of-life-container page-padding'}>
            <div className="rule-of-life-intro">
                <p className="quote">
                    The Spirit of the Lord is on me, for He has anointed me, to bring the good news to the afflicted.
                    He has sent me to proclaim liberty to captives, sight to the blind, to let the oppressed, go free,
                    to proclaim a year of favour from the Lord
                </p>
                <small className="quote-like">(Luke 4:18-19)</small>
                <p>
                    Sent by the Father and consecrated by the Holy Spirit, Jesus Christ came to save all people. He
                    continues in the world of today this mission of salvation of which the Church is the sacrament. In
                    the midst
                    of God’s people, among the numerous and varied vocations which the Holy Spirit inspires,
                    we Spiritans are called by the Father and “set apart” (Act13:2)
                    to follow Jesus and to announce the Good News of the Kingdom.
                </p>
            </div>
            <div className="rule-of-life-body">
                <div className="rule-of-life-section">
                    <h3>the nature of the congregation</h3>
                    <p>
                        We respond to this call in a religious missionary institute, the Congregation of the Holy Spirit
                        under
                        the protection of the Immaculate Heart of Mary.The charisms of our Founders,
                        Claude Poullart des Places and Francis Libermann, and fidelity to our tradition urge us to
                        respond
                        creatively to the needs of evangelisation of our time (cf. SRL 4;12).
                    </p>
                </div>

                <div className="rule-of-life-section">
                    <h3>the unity of spiritan life</h3>
                    <p>
                        The “apostolic life” is at the heart of our Spiritan vocation. It is “that life of love and of
                        holiness
                        lived on earth by the Son of God in order to save and sanctify people. By it He continually
                        sacrificed
                        Himself, thereby glorifying the Father and saving the world” (Rule 1849; N.D. X, 505). So that
                        we may lead
                        this apostolic life in Christ’s footsteps, our consecration includes three essential dimensions:
                        <ul>
                            <li>the proclamation of the Good News,</li>
                            <li>the practice of the evangelical counsels</li>
                            <li>and a life in fraternal and praying community.</li>
                        </ul>
                    </p>
                </div>

                <div className="rule-of-life-section">
                    <h3>our mission in the church</h3>
                    <p>
                        The evangelisation of the “poor” (cf. Lk 4:18) is our purpose (cf. N.D. XIII, 170). Therefore,
                        we go
                        especially to peoples, groups and individuals who have not yet heard the message of the Gospel
                        or who have
                        scarcely heard it, to those whose needs are the greatest, and to the oppressed (cf. N.D. II,
                        241). We also
                        willingly accept tasks for which the Church has difficulty.
                        in finding workers.
                    </p>
                </div>

                <div className="rule-of-life-section">
                    <h3>the spiritan apostolic spirit</h3>
                    <p>
                        We live out our mission in willing obedience to the Holy Spirit, taking Mary as our model.
                        This condition of habitual fidelity to the inspiration of the Holy Spirit is the “practical
                        union”.
                        of which Libermann speaks (N.D. XIII, 699-706). It is the wellspring of our apostolic zeal
                        and leads us to being completely available and making a complete gift of ourselves.
                    </p>
                </div>

                <div className="rule-of-life-section">
                    <h3>dedication</h3>
                    <p>
                        We are dedicated to the Holy Spirit, author of all holiness and“source of the apostolic spirit”
                        (N.D. X,
                        568).We place ourselves under the protection of the Immaculate Heart of Mary,who was filled
                        beyond measure
                        by the same Spirit“with the fullness of holiness and apostolic zeal” (N.D. X, 568).
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RuleOfLife;