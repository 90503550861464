import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {IMediaTab, Media} from "../interfaces";
import CarouselModal from "../../../Atoms/CarouselModel/carouselModalContainer";
import '../styles.scss'
import MediaCard from "../../../Atoms/MediaCard/mediaCardContainer";
import ApiCrud from '../../../../API/services';
import {UnderConstruction} from "../../../../Pages/UnderConstruction";
import {LoadingSpinner} from "../../../Atoms/LoadingSpin";

const MediaTab = ({type}: IMediaTab) => {
    const [mediaList, setMediaList] = useState<Media[]>()
    const [error, setError] = useState<any>();
    const [showCarousel, setShowCarousel] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const handleClick = (index: number) => {
        setShowCarousel(true)
        setActiveIndex(index)
    }

    useEffect(() => {
        const AbortRequest = new AbortController()
        const {getAll} = ApiCrud({endpoint: `gallery/${type}/`, signal: AbortRequest.signal})
        !mediaList && getAll()
            .then((res) => {
                return (
                    setMediaList(res.data.items)
                )
            })
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    setError(err.name)
                }

            })

        return () => {

            setError(undefined)
            setMediaList(undefined)
            AbortRequest.abort()
        }
    }, [type]);
    return (
        <Container className={'media-tab-container'}>
            {
                mediaList && type !== 'videos' ?
                    <>
                        <Row className="gap-3 media-tab-card-set">
                            {mediaList?.map((media, index) => {
                                return (
                                    <Col key={"col" + index} className="media-tab-card">
                                        <MediaCard media={media} onClick={() => handleClick(index)}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                    </>

                    :
                    <>
                        {
                            type === 'videos' ?
                                <UnderConstruction/>
                                :
                                <div className={"page loading-spinner"}>
                                    <LoadingSpinner/>
                                </div>
                        }
                    </>
            }
            {
                showCarousel &&
                <CarouselModal
                    setShow={setShowCarousel}
                    show={showCarousel}
                    className={"media-tab-carousel"}
                    images={mediaList}
                    activeIndex={activeIndex}
                />
            }
        </Container>
    );
};

export default MediaTab;