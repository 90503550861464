import React from 'react';
import {IGallery} from "./interface";
import {GalleryTabs} from "../../Components/Molecules/GalleryTabs";

const GalleryContainer = ({}: IGallery) => {
    return (
        <>
            <GalleryTabs/>
        </>
    );
};

export default GalleryContainer;