import React, {useEffect, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import ApiCrud from "../../API/services";


const PrayerRequest = () => {
    const [values, setValues] = useState<any>({request_type: 'prayer', sender: '', email: '', content: '', subject: ''})
    const [validated, setValidated] = useState(false);
    const [message, setMessage] = useState<{ text: string, type: string }>()

    const onChangeHandler = (e: any) => {
        setValues((curr: any) => {
            return (
                curr ?
                    {...curr, [e.target.name]: e.target.value ?? e.target.innerText}
                    :
                    {[e.target.name]: e.target.value ?? e.target.innerText}
            )
        })
    }


    const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const {create} = ApiCrud({endpoint: 'requests/', data: values})
        create().then(() => {
            setValidated(false);
            setValues({request_type: 'prayer', sender: '', email: '', content: '', subject: ''})
            setMessage({text: 'Sent!', type: 'success'})
        })
            .catch(() => {
                setMessage({text: 'Failed!', type: 'failed'})
            })
        setValidated(true);
    }

    useEffect(() => {
        setTimeout(() => setMessage(undefined), 3000)
    }, [message])
    return (
        <div className={"prayer-request-form-container "}>
            <div className="prayer-request-header">
                <h1>Prayer Request</h1>
                <div className="prayer-request-header-text">
                    <p className={'quote'}>
                        Is any one of you sick? He should call the elders of the church to pray over him and anoint him
                        with oil in the name of the Lord. And the prayer offered in faith will make the sick person
                        well…
                    </p>
                    <small className="quote-like">
                        (James 5: 14 – 15)
                    </small>
                </div>
            </div>
            <Form
                className={"prayer-request-form"}
                onSubmit={(values) => onSubmitHandler(values)}
                noValidate
                validated={validated}
            >
                <Form.Group className="mb-3 prayer-request-form-group">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="your full name"
                        name={'sender'}
                        value={values['sender']}
                        onChange={(e) => onChangeHandler(e)}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3 prayer-request-form-group" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="your email address"
                        name={'email'}
                        value={values['email']}
                        onChange={(e) => onChangeHandler(e)}
                        required
                    />
                    <Form.Text className="text-muted">
                        We'll never share your email
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3 prayer-request-form-group">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="subject"
                        name={'subject'}
                        value={values['subject']}
                        onChange={(e) => onChangeHandler(e)}
                        required
                    />
                </Form.Group>
                <Form.Group className="mb-3 prayer-request-form-group">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="message"
                        name={'content'}
                        value={values['content']}
                        onChange={(e) => onChangeHandler(e)}
                        required
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </div>
    );
};

export default PrayerRequest;