import React from 'react';
import {Card} from "react-bootstrap";
import {IMediaCard} from "./interfaces";
import './styles.scss'

const MediaCard = ({media, ...props}: IMediaCard) => {

    return (
        <div
            className={"media-card-container"}
        >
            <Card
                className={"media-card"}
                onClick={media.onClick}
            >
                {
                    media.type === "image" ?
                        <Card.Img
                            variant="top"
                            src={media.url}
                            // src={MainURL + media.url}
                            {...props}
                            className={"media-card-img " + props.className}
                        />
                        :
                        <Card.Img
                            variant="top"
                            src={media.url}
                            {...props}
                            className={"media-card-img " + props.className}
                        />
                }
            </Card>
        </div>
    );
};

export default MediaCard;