import React, {useEffect} from 'react';
import ParishBG from '../../Assets/Images/education.jpeg'
import EducationBG from '../../Assets/Images/170415_Easter_Vigil_225.jpeg'
import {Link} from "react-router-dom";
import {navbarBackgroundChanger, navbarButtonColorChanger} from '../../Helpers/navbarColorHelper';

const WhatWeDoContainer = () => {

    useEffect(() => {
        navbarButtonColorChanger('#d0bdbd');
        navbarBackgroundChanger('transparent')
        return () => {
            navbarButtonColorChanger('#091b27')
            navbarBackgroundChanger('var(--body-background)')
        }

    }, []);

    return (
        <div className={'page what-we-do-container'}>
            <div className="what-we-do left" style={{backgroundImage: `url(${ParishBG})`}}>
                <Link to={'/what_we_do/parishes'}><h1>Parishes</h1></Link>
                <div className="what-we-do-text">
                    <p>
                        In Australia, we have seven parishes, most with schools attached, located in Queensland,
                        Victoria, New South Wales and Western Australia.
                    </p>
                    <p>
                        From 2013, Papua New Guinea has welcomed Spiritans in four Parishes.
                    </p>
                </div>
            </div>
            <div className="what-we-do right" style={{backgroundImage: `url(${EducationBG})`}}>
                <Link to={'/what_we_do/education'}><h1>Education</h1></Link>
                <div className="what-we-do-text">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque facere impedit, ipsa ipsum iste
                        laborum perferendis quisquam voluptatem. Dolorem perferendis perspiciatis quisquam. In nesciunt
                        quam quo sed ullam! Doloribus, officia!
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WhatWeDoContainer;