import React, {useEffect, useState} from 'react';
import JoinForm from "./JoinForm";
import ApiCrud from "../../../API/services";
import {LoadingSpinner} from "../../../Components/Atoms/LoadingSpin";

export interface IVolunteerValues {
    full_name?: string,
    phone?: string,
    email?: string,
    note?: string,
    fields?: string[]
}

export interface IFieldOfHelp {
    id: string,
    name: string,
    is_open: boolean
}

const VolunteerContainer = () => {
    const [data, setData] = useState<any>()

    useEffect(() => {
        const AbortRequest = new AbortController()
        const {getAll} = ApiCrud({endpoint: `get_involved/volunteer_page/`, signal: AbortRequest.signal})
        getAll()
            .then((res) => {
                return (
                    setData(res.data.items[0])
                )
            })
            .catch((err) => {
                if (err.code === 'ERR_CANCELED') {
                    return
                }
            })

        return () => {
            setData(undefined)
            AbortRequest.abort()
        }
    }, []);

    return (
        <>
            {data ?
                <div className={'page page-padding volunteer-container'}>
                    <h1 className="volunteer-title">Volunteer</h1>
                    <div className="volunteer-body">
                        <div className="volunteer-content volunteer-section">
                            <h3>{data.title}</h3>
                            <big>{data.first_paragraph}</big>
                            <big>
                                {data.fild_of_help_leading_sentence}

                                <ul>
                                    {data.fields_of_help?.map((field: any) => {
                                        return (
                                            <>
                                                {
                                                    field.is_open &&
                                                    <li>{field.name}</li>
                                                }
                                            </>
                                        )
                                    })
                                    }
                                </ul>
                            </big>
                            <big>{data.second_paragraph}</big>
                            <big>{data.third_paragraph}</big>
                        </div>
                        <div className="volunteer-form volunteer-section">
                            <JoinForm
                                hasFieldsOfHelp={true}
                                endPoint={'people/volunteer/'}
                                submitButtonName={'volunteer'}
                                fieldsOfHelp={data.fields_of_help}
                            />
                        </div>
                    </div>
                </div>
                :
                <div className={"page loading-spinner"}>
                    <LoadingSpinner/>
                </div>
            }
        </>
    );
};

export default VolunteerContainer;