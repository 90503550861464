import React, {useState} from 'react';
import {ICarouselModal} from "./interfaces";
import {Carousel, Col, Form, Modal, Row} from "react-bootstrap";
import './styles.scss'
import {FiSettings} from "react-icons/fi";

const CarouselModal = ({setShow, show, images, activeIndex, ...props}: ICarouselModal) => {
    const [slideShow, setSlideShow] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [slideTimer, setSlideTimer] = useState(2)
    const handleClose = () => setShow(false);
    return (
        <Modal
            show={show}
            onHide={handleClose}
            {...props}
            className={"carousel-modal-container " + props.className}
        >
            <Modal.Body>
                <div
                    className={"carousel-model-controls"}
                    onMouseEnter={() => setShowSettings(true)}
                >
                    <div className="carousel-model-controls-settings">
                        <FiSettings
                            onClick={() => setShowSettings(!showSettings)}
                            title={"hide menu"}
                        />
                    </div>
                    {
                        showSettings &&
                        <div className="carousel-model-controls-items-wrap">
                            <div className="carousel-model-controls-items">

                                <Form.Group as={Row}>
                                    <Form.Label
                                        htmlFor={"auto-timer"}
                                        column
                                        sm={8}
                                    >
                                        Auto Slide
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Check
                                            type={'switch'}
                                            className={"carousel-model-slide-btn"}
                                            onChange={() => setSlideShow(!slideShow)}
                                            defaultChecked={slideShow}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Form.Label
                                        htmlFor={"slide-timer"}
                                        column
                                        sm={8}
                                    >
                                        Slide Timer (seconds)
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control
                                            id={"slide-timer"}
                                            placeholder="3.2"
                                            aria-label="slide timer"
                                            defaultValue={slideTimer}
                                            onChange={(e) => setSlideTimer(Number(e.target.value))}
                                            type={"number"}
                                        />
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                    }
                </div>
                <Carousel
                    defaultActiveIndex={activeIndex}
                    interval={slideShow ? slideTimer * 1000 : null}
                    title={"auto slide pauses when you hover the image"}
                    slide={false}
                >
                    {images?.map((image) => {
                        return (
                            <Carousel.Item>
                                <img
                                    // src={MainURL + image.url}
                                    src={image.url}
                                    alt={image.title}
                                />
                                <Carousel.Caption>
                                    <h3>{image.title}</h3>
                                    <p>{image.description}</p>
                                    <small>{image.extra}</small>
                                </Carousel.Caption>
                            </Carousel.Item>
                        )
                    })
                    }
                </Carousel>
            </Modal.Body>
        </Modal>
    );
};

export default CarouselModal;