import {LabelMaker} from "../../Helpers/textHelpers";

export function AboutUsSection(header: any, index: number, margin: string) {

    return (
        <div id={header.title.toLowerCase().replaceAll(' ', '_')}
             className={`page-padding about-us-section`}
             key={`about-us-${index}`}>
            <div className={`about-us-section-title ${margin[index]}`}>
                <h1>{LabelMaker(header.title, ' ')}</h1>
            </div>
            <div className={`about-us-section-body ${margin[index]}`}>
                <div className={`about-us-section-image`}>
                    {/*<img src={MainURL + header.image.url} alt={header.title}*/}
                    <img src={header.image.url} alt={header.title}
                         className={`${margin[index]}`}/>
                </div>
                <div className={`about-us-section-text`}>
                    <div
                        className={`about-us-content`}
                        dangerouslySetInnerHTML={{__html: header.content}}/>
                </div>
            </div>
        </div>
    );
}